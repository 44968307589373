<style>

</style>

<template>
    <div id="new-quote" style="padding: 0 1.5rem;">
        <Consol></Consol>
        <div class="row">
            <div class="col-12 col-md-4 col-lg-4  mb-4">
                <div class="card h-100 bg2rb bg2car">
                    <div class="card-body p-3">
                        <div class="d-flex flex-column h-100">
                            <h5 class="font-weight-bolder">{{$t('carInsurance')}}</h5>
                            <div class="text-center mb-3">
                                <img src="img/nq-car.png" alt="Car insurance" class="max-w100" />
                            </div>
                            <button v-if="!isNullOrEmpty(Global.GAEncodeUrl.agent)"
                                class="btn btn-round bg-primary text-white max-w-200 font-weight-bold mb-1 icon-move-right mt-auto" 
                                @click="getQuoteNewTab('Car')"
                                data-selenium-name="get-quote-car">
                                {{$t('getQuote')}}
                                <i class="fas fa-arrow-right text-sm ms-1" aria-hidden="true"></i>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <!-- <div class="col-12 col-md-4 col-lg-4  mb-4">
                <div class="card h-100 bg2rb bg2ew">
                    <div class="card-body p-3">
                        <div class="d-flex flex-column h-100"> 
                            <h5 class="font-weight-bolder">{{$t('extendedWarranty')}}</h5>
                            <div class="text-center mb-3">
                                <img src="img/nq-ew.png" alt="Extended warranty insurance" class="max-w100" />
                            </div>
                            <button v-if="!isNullOrEmpty(Global.GAEncodeUrl.agent)"
                                class="btn btn-round bg-primary text-white max-w-200 font-weight-bold mb-1 icon-move-right mt-auto" 
                                @click="getQuoteNewTab('ExtendedWarranty')"
                                data-selenium-name="get-quote-ew">
                                {{$t('getQuote')}}
                                <i class="fas fa-arrow-right text-sm ms-1" aria-hidden="true"></i>
                            </button>
                        </div>
                    </div>
                </div>
            </div> -->
            <div class="col-12 col-md-4 col-lg-4  mb-4">
                <div class="card h-100 bg2rb bg2mb">
                    <div class="card-body p-3">
                        <div class="d-flex flex-column h-100">
                            <h5 class="font-weight-bolder">{{$t('motorBikeInsurance')}}</h5>
                            <div class="text-center mb-3">
                                <img src="img/nq-mb.png" alt="Motorbike insurance" class="max-w100" />
                            </div>
                            <button v-if="!isNullOrEmpty(Global.GAEncodeUrl.agent)"
                                class="btn btn-round bg-primary text-white max-w-200 font-weight-bold mb-1 icon-move-right mt-auto" 
                                @click="getQuoteNewTab('MotorBike')"
                                data-selenium-name="get-quote-motorbike">
                                {{$t('getQuote')}}
                                <i class="fas fa-arrow-right text-sm ms-1" aria-hidden="true"></i>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-12 col-md-4 col-lg-4  mb-4" v-show="Global.brokerDetails.accountSource != 'Traditional broker' && Global.brokerDetails.accountSource != 'Ground agent'">
                <div class="card h-100 bg2rb bg2pa">
                    <div class="card-body p-3">
                        <div class="d-flex flex-column h-100">
                            <h5 class="font-weight-bolder">{{$t('PAInsurance')}}</h5>
                            <div class="text-center mb-3">
                                <img src="img/nq-pa.png" alt="Personal accident insurance" class="max-w100" />
                            </div>
                            <button v-if="!isNullOrEmpty(Global.GAEncodeUrl.agent)"
                                class="btn btn-round bg-primary text-white max-w-200 font-weight-bold mb-1 icon-move-right mt-auto" 
                                @click="getQuoteNewTab('PA')"
                                data-selenium-name="get-quote-pa">
                                {{$t('getQuote')}}
                                <i class="fas fa-arrow-right text-sm ms-1" aria-hidden="true"></i>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-12 col-md-4 col-lg-4  mb-4" v-show="Global.brokerDetails.accountSource != 'Traditional broker' && Global.brokerDetails.accountSource != 'Ground agent'">
                <div class="card h-100 bg2rb bg2cancer">
                    <div class="card-body p-3">
                        <div class="d-flex flex-column h-100">
                            <h5 class="font-weight-bolder">{{$t('cancerInsurance')}}</h5>
                            <div class="text-center mb-3">
                                <img src="img/nq-cancer.png" alt="Cancer insurance" class="max-w100" />
                            </div>
                            <button v-if="!isNullOrEmpty(Global.GAEncodeUrl.agent)"
                                class="btn btn-round bg-primary text-white max-w-200 font-weight-bold mb-1 icon-move-right mt-auto" 
                                @click="getQuoteNewTab('Cancer')"
                                data-selenium-name="get-quote-cancer">
                                {{$t('getQuote')}}
                                <i class="fas fa-arrow-right text-sm ms-1" aria-hidden="true"></i>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-12 col-md-4 col-lg-4  mb-4" v-show="Global.brokerDetails.accountSource != 'Traditional broker' && Global.brokerDetails.accountSource != 'Ground agent'">
                <div class="card h-100 bg2rb bg2ci">
                    <div class="card-body p-3">
                        <div class="d-flex flex-column h-100">                    
                            <h5 class="font-weight-bolder">{{$t('CIInsurance')}}</h5>
                            <div class="text-center mb-3">
                                <img src="img/nq-ci.png" alt="Critical illness insurance" class="max-w100" />
                            </div>
                            <button v-if="!isNullOrEmpty(Global.GAEncodeUrl.agent)"
                                class="btn btn-round bg-primary text-white max-w-200 font-weight-bold mb-1 icon-move-right mt-auto"
                                @click="getQuoteNewTab('CI')"
                                data-selenium-name="get-quote-ci">
                                {{$t('getQuote')}}
                                <i class="fas fa-arrow-right text-sm ms-1" aria-hidden="true"></i>
                            </button>
                        </div> 
                    </div>
                </div>
            </div>
            <div class="col-12 col-md-4 col-lg-4  mb-4" v-show="Global.brokerDetails.accountSource == 'Ground agent'">
                <div class="card h-100 bg2rb bg2pa">
                    <div class="card-body p-3">
                        <div class="d-flex flex-column h-100">                    
                            <h5 class="font-weight-bolder">{{$t('PAComboSave')}}</h5>
                            <div class="text-center mb-3">
                                <img src="img/nq-pa.png" alt="Personal accident insurance" class="max-w100" />
                            </div>
                            <button v-if="!isNullOrEmpty(Global.GAEncodeUrl.agent)"
                                class="btn btn-round bg-primary text-white max-w-200 font-weight-bold mb-1 icon-move-right mt-auto"
                                @click="getQuoteNewTab('PAComboSave')"
                                data-selenium-name="get-quote-ci">
                                {{$t('getQuote')}}
                                <i class="fas fa-arrow-right text-sm ms-1" aria-hidden="true"></i>
                            </button>
                        </div> 
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Global from "@/stores/GlobalVariables.js";
import mixin from "@/components/Mixin";
import Consol from "@/components/Consol";

export default {
    mixins: [mixin],
    name: "NewQuote",
    components: { Consol },
    data: function() {
        return {
            Global
        }
    },
    methods: {
        getQuoteNewTab(productType){
            let vm = this;
            let url = vm.Global.GAEncodeUrl.agent;
            let utm_source = "Sales-Intermediary_" + vm.Global.brokerDetails.intermediaryAccountId;
            if(productType == 'MotorBike') {
                url += "&p=motorbike"; 
            } else if(productType == 'PA') {
                url += "&p=pa"; 
            } else if(productType == 'Cancer') {
                url += "&p=health"; 
            } else if(productType == 'ExtendedWarranty') {
                url += "&p=extended-warranty"; 
            } else if(productType == 'CI') {
                url += "&p=ci"; 
            } else if(productType == 'PAComboSave') {
                url += "&p=pac"; 
                // https://insure.uat-roojai.com/#/?pageName=quotationInput&rid=zkvZkuw1tNI9a8Y8k4a2X8dv0CDOsgDsqTnwDAFHm94%3D&p=pac&lang=th&utm_source=Sales-Intermediary_001p000000tJreTAAS
            }
            
            url += "&lang=" + Global.locale; 
            url += "&utm_source=" + utm_source; 
            console.log("getQuoteNewTab ~ url:",url);
            window.open(url, '_blank');
        },
    },
    created() {
        this.eventTracking('mainMenu', 'Menu_Getquote', 'Portal');
    }
}
</script>

<i18n>
{
    "en":{
    "getQuote": "Get quote",
    "carInsurance": "Car insurance",
    "extendedWarranty": "Extended warranty insurance",
    "motorBikeInsurance": "Motorbike insurance",
    "PAInsurance": "Personal accident insurance",
    "cancerInsurance": "Cancer insurance",
    "CIInsurance": "Critical illness insurance",
    "PAComboSave": "PA Combo Save"
  },
  "th":{
    "getQuote": "เช็คราคา",
    "carInsurance": "ประกันรถยนต์",
    "extendedWarranty": "ประกันอะไหล่รถยนต์",
    "motorBikeInsurance": "ประกันมอเตอร์ไซค์",
    "PAInsurance": "ประกันอุบัติเหตุส่วนบุคคล",
    "cancerInsurance": "ประกันมะเร็ง",
    "CIInsurance": "ประกันโรคร้ายแรง",
    "PAComboSave": "PA Combo Save"
  },
  "zh":{
    "getQuote": "Get quote",
    "carInsurance": "汽车保险",
    "extendedWarranty": "延长车辆质保保险",
    "motorBikeInsurance": "摩托车保险",
    "PAInsurance": "个人意外保险",
    "cancerInsurance": "癌症保险",
    "CIInsurance": "Critical illness insurance",
    "PAComboSave": "PA Combo Save"
  }
}
</i18n>

