export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "getQuote": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Get quote"])},
        "carInsurance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Car insurance"])},
        "extendedWarranty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Extended warranty insurance"])},
        "motorBikeInsurance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Motorbike insurance"])},
        "PAInsurance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Personal accident insurance"])},
        "cancerInsurance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancer insurance"])},
        "CIInsurance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Critical illness insurance"])},
        "PAComboSave": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PA Combo Save"])}
      },
      "th": {
        "getQuote": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เช็คราคา"])},
        "carInsurance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ประกันรถยนต์"])},
        "extendedWarranty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ประกันอะไหล่รถยนต์"])},
        "motorBikeInsurance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ประกันมอเตอร์ไซค์"])},
        "PAInsurance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ประกันอุบัติเหตุส่วนบุคคล"])},
        "cancerInsurance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ประกันมะเร็ง"])},
        "CIInsurance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ประกันโรคร้ายแรง"])},
        "PAComboSave": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PA Combo Save"])}
      },
      "zh": {
        "getQuote": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Get quote"])},
        "carInsurance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["汽车保险"])},
        "extendedWarranty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["延长车辆质保保险"])},
        "motorBikeInsurance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["摩托车保险"])},
        "PAInsurance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["个人意外保险"])},
        "cancerInsurance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["癌症保险"])},
        "CIInsurance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Critical illness insurance"])},
        "PAComboSave": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PA Combo Save"])}
      }
    }
  })
}
